$(document).ready(function() {

      $('.image__slider').slick({
          dots: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          variableWidth: false,
          mobileFirst: true,
          prevArrow: '<button type="button" class="slick-prev"><</button>',
          nextArrow: '<button type="button" class="slick-next">></button>',
          responsive: [{
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                mobileFirst: false,
                variableWidth: true
              }
            }, {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                variableWidth: true
              }
            },
              {
                breakpoint: 768,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 400,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });

          $('.image__slider-single').slick({
          dots: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          variableWidth: false,
          mobileFirst: true,
          prevArrow: '<button type="button" class="slick-prev"><</button>',
          nextArrow: '<button type="button" class="slick-next">></button>',
          });

    $('a.scroll[href*=#]:not([href=#])').click(function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

});
